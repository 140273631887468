import(/* webpackMode: "eager" */ "/home/site/web/sapient.pro/private/sapientpro-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/site/web/sapient.pro/private/sapientpro-frontend/src/components/CookiesPolicyBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/site/web/sapient.pro/private/sapientpro-frontend/src/components/GoogleAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/site/web/sapient.pro/private/sapientpro-frontend/src/components/ReffererDetector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/site/web/sapient.pro/private/sapientpro-frontend/src/store/JotaiProvider.tsx");
;
import(/* webpackMode: "eager" */ "/home/site/web/sapient.pro/private/sapientpro-frontend/src/styles/globals.scss");
